import { Component, computed, effect, inject, signal, untracked } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { map } from "rxjs";

import { PercentageValuePipe } from "../../../../../core/pipes";
import { AdaaHelper } from "../../../../../core/utils";
import { Constants } from "../../../../../shared/constants/constants";
import { Language } from "../../../../../shared/constants/enums";
import {
  EkpiItemTableValueModel,
  ItemTableValueModel,
  KPIItem,
  MetricReadingItemData,
  MetricReadingItemValue,
} from "../../../../../shared/models";
import { KpisApiService, LanguageService } from "../../../../../shared/services";
@Component({
  selector: "adaa-master-ekpi-table-values",
  imports: [TranslateModule, PercentageValuePipe],
  templateUrl: "./master-ekpi-table-values.component.html",
  styleUrl: "./master-ekpi-table-values.component.scss",
})
export class MasterEkpiTableValuesComponent {
  readonly modal = inject(NgbActiveModal);
  readonly languageService = inject(LanguageService);
  private readonly _translateService = inject(TranslateService);
  private readonly _kpiApiService = inject(KpisApiService);

  kpiId = signal<number | undefined>(undefined);
  periodId = signal<number | undefined>(undefined);
  frequency = signal<number | undefined>(undefined);
  periodCycle = signal<string | undefined>(undefined);
  measurementUnit = signal<number | undefined>(undefined);
  isNoFormula = signal<boolean>(false);
  isBounded = signal<boolean>(false);
  data = signal<EkpiItemTableValueModel[]>([]);

  isPercentage = computed(() => this.measurementUnit() === Constants.MEASUREMENT.CONSTANT_MEASUREMENT_PERCENTAGE);
  tableData = computed(() => {
    const data = this.data();
    const results: Record<string, Array<ItemTableValueModel & { entityId: number; periodLabel: string }>> = {};

    if (data.length === 0) return results;

    for (const item of data) {
      const name = AdaaHelper.getItemValueByToken(item, "entityName");
      results[name] = item.itemData.map(($i) => ({
        ...$i,
        entityId: item.entityId,
        periodLabel: this._getPeriodLabel($i),
      }));
    }
    return results;
  });
  tableDataCategories = computed(() => {
    const data = this.tableData();
    return Object.keys(data as unknown as Record<string, unknown>);
  });

  readonly kpiType = Constants.KPI_TYPE.EKPI;

  constructor() {
    effect(() => {
      const args = {
        id: this.kpiId()!,
        periodId: this.periodId()!,
        kpiType: this.kpiType,
        frequency: this.periodCycle()!,
      };

      untracked(() => {
        this._kpiApiService
          .getItemDataByTypeAndId(args)
          .pipe(map((res) => res.responseData))
          .subscribe({
            next: (data) => {
              this.data.set(data);
            },
          });
      });
    });
  }

  private _getPeriodLabel({ year, month }: ItemTableValueModel) {
    const SEMESTERS = [
      [1, 2, 3, 4, 5, 6],
      [7, 8, 9, 10, 11, 12],
    ];
    const QUARTERS = [
      [1, 2, 3],
      [4, 5, 6],
      [7, 8, 9],
      [10, 11, 12],
    ];
    const pad = (num: number, size: number) => {
      const result = `${num}`;
      if (result.length < size) {
        return "0" + result;
      }
      return result;
    };

    switch (this.frequency()) {
      case Constants.FREQUENCY.EVERY_TWO_YEARS:
      case Constants.FREQUENCY.EVERY_THREE_YEAR:
      case Constants.FREQUENCY.EVERY_FOUR_YEARS:
      case Constants.FREQUENCY.EVERY_FIVE_YEARS:
      case Constants.FREQUENCY.ANNUAL: {
        return `${year}`;
      }

      case Constants.FREQUENCY.SEMIANNUAL: {
        const index = SEMESTERS.findIndex((list) => !!list.find((c) => +c === month));
        return `S${index + 1} ${year}`;
      }

      case Constants.FREQUENCY.QUARTERLY: {
        const index = QUARTERS.findIndex((list) => !!list.find((c) => +c === month));
        return `Q${index + 1} ${year}`;
      }

      case Constants.FREQUENCY.MONTHLY: {
        return `${pad(month, 2)} / ${year}`;
      }

      default: {
        return `${year}`;
      }
    }
  }

  public printContent(): void {
    const printableWindow = window.open("", "_blank", "width=2000,height=2000,scrollbars=yes,resizable=yes");

    if (!printableWindow) {
      return;
    }

    const title = this._translateService.instant("ekpi.card_view_all_data_button");
    const isArabic = AdaaHelper.getCurrentLang() === Language.Arabic;

    const rowLabelsMap: Record<string, string> = {
      Period: this._translateService.instant("common.tables.period"),
      Target: this._translateService.instant("ekpi.target"),
      Actual: this._translateService.instant("ekpi.actual"),
      "Annual Actual": this._translateService.instant("data_entry.annual_actual"),
      Performance: this._translateService.instant("ekpi.performance"),
      "YTP Performance": this._translateService.instant("ekpi.performance_ytp"),
    };

    const categories = this.tableDataCategories();
    const tableData: Record<string, unknown> = this.tableData();

    let content = `
      <html dir="${isArabic ? "rtl" : "ltr"}">
      <head>
        <title>${title}</title>
        <style>
  body {
    font-family: Arial, sans-serif;
    padding: 20px;
    margin: 0;
    overflow-x: auto;
  }

  h1, h2 {
    text-align: ${isArabic ? "right" : "left"};
  }

  .table-wrapper {
    overflow-x: auto;
    margin-bottom: 30px;
    width: 100%;
  }

  table {
    border-collapse: collapse;
    width: max-content;
    min-width: 100%;
    table-layout: auto;
  }

  th, td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
    vertical-align: top;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 150px;
  }

  th {
    background-color: #f0f0f0;
  }

  .print-section {
    page-break-inside: avoid;
    break-inside: avoid;
    margin-bottom: 50px;
    display: block;
  }

  table, thead, tbody, tr, td, th {
    page-break-inside: avoid !important;
    break-inside: avoid !important;
  }

  @media print {
    body {
      overflow-x: visible !important;
    }

    .table-wrapper {
      overflow: visible !important;
    }

    table {
      width: 100% !important;
    }

    h1, h2 {
      page-break-after: avoid;
    }

    .print-section {
      page-break-inside: avoid;
      break-inside: avoid;
    }

    td, th {
      font-size: 10pt;
    }
  }
</style>
      </head>
      <body>
        <h1>${title}</h1>
    `;

    categories.forEach((key: string) => {
      const rows: Record<string, string[]> = {
        Period: [],
        Target: [],
        Actual: [],
        "Annual Actual": [],
        Performance: [],
        "YTP Performance": [],
      };

      const items = tableData[key] as KPIItem[];
      if (!items) return;

      items.forEach((item) => {
        rows["Period"].push(item.periodLabel || "");
        rows["Target"].push(
          item.isBaselineYear ? this._translateService.instant("ekpi.baselineYear") : this._percentageValue(item.target)
        );
        rows["Actual"].push(item.ignored ? "-" : this._percentageValue(item.actual));
        rows["Annual Actual"].push(item.ignored ? "-" : this._percentageValue(item.annualActual));
        rows["Performance"].push(this._percentageValue(item.score));
        rows["YTP Performance"].push(this._percentageValue(item.scoreYtp));

        if (item.metricReadingsItemData?.length) {
          item.metricReadingsItemData?.forEach((metric: MetricReadingItemData) => {
            if (metric.metricName === Constants.FORMULA_STRING.NOFORMULA) return;

            const label = `${metric.metricName} - ${metric.metricDescription}`;
            rows[label] = metric.metricReadingsItemValues.map((valObj: MetricReadingItemValue) =>
              valObj.value != null ? valObj.value.toLocaleString() : "-"
            );
          });
        }
      });

      content += `
        <div class="print-section">
          <h2>${key}</h2>
          <div class="table-wrapper">
            <table><tbody>
      `;
      for (const [label, values] of Object.entries(rows)) {
        const translatedLabel = rowLabelsMap[label] || label;
        content += `<tr><th>${translatedLabel}</th>`;
        values.forEach((val) => (content += `<td>${val}</td>`));
        content += `</tr>`;
      }
      content += `</tbody></table></div></div>`;
    });

    content += `
      <script>
        window.onload = function() {
          window.scrollTo(0, 0);
        };
      </script>
    </body>
    </html>`;

    printableWindow.document.write(content);
    printableWindow.document.close();
    printableWindow.focus();
    printableWindow.print();
    printableWindow.close();
  }

  private _percentageValue(value: number | null | undefined): string {
    return value != null ? `${value.toFixed(2)}%` : "-";
  }
}
