import { DatePipe } from "@angular/common";
import { Component, computed, inject, input, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { CurrencyValuePipe, TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import {
  CommentsComponent,
  DataTableComponent,
  DirectionsCardGridComponent,
  DoughnutComponent,
  EntityPlanChangeDetectionComponent,
  FloatActionComponent,
  PictureComponent,
  ProgressIndicatorComponent,
  ReadMoreComponent,
  TpTimelineComponent,
} from "../../../../shared/components";
import { genericFloatButtons } from "../../../../shared/components/general/float-action";
import { Constants } from "../../../../shared/constants/constants";
import { ExtendedUserModelType } from "../../../../shared/models";
import { SystemLayoutService, TpDetailsService, UserService } from "../../../../shared/services";

@Component({
  selector: "adaa-tp-view",
  standalone: true,
  imports: [
    DatePipe,
    TranslateTokenPipe,
    CurrencyValuePipe,
    TranslateModule,
    RouterModule,
    FontAwesomeModule,
    CommentsComponent,
    DataTableComponent,
    DirectionsCardGridComponent,
    DoughnutComponent,
    FloatActionComponent,
    PictureComponent,
    ProgressIndicatorComponent,
    ReadMoreComponent,
    TpTimelineComponent,
    EntityPlanChangeDetectionComponent,
  ],
  templateUrl: "./tp-view.component.html",
  styleUrl: "./tp-view.component.scss",
})
export class TpViewComponent implements OnInit {
  private _router = inject(Router);
  private _iconLibrary = inject(FaIconLibrary);
  private _systemLayoutService = inject(SystemLayoutService);
  private readonly _floatActions = genericFloatButtons();
  tpDetailsService = inject(TpDetailsService);
  private _userService = inject(UserService);

  id = input.required<string>();

  private readonly _untilDestroyed = AdaaHelper.untilDestroyed();
  AdaaHelper = AdaaHelper;
  constants = Constants;

  paramsStringMokpi = computed(() => `nationalProjectId=${this.tpDetailsService.project()?.id}`);

  public get contributingEntities() {
    if (!AdaaHelper.isDefined(this.tpDetailsService.project())) return [];
    const list = this.tpDetailsService.project()!.contributingEntities || [];
    return list.filter((e) => !AdaaHelper.isDefined(e.otherEntityId));
  }

  public get otherContributingEntities() {
    if (!AdaaHelper.isDefined(this.tpDetailsService.project())) return [];
    const list = this.tpDetailsService.project()!.contributingEntities || [];
    return list.filter((e) => AdaaHelper.isDefined(e.entityId) && AdaaHelper.isDefined(e.otherEntityId));
  }

  public ngOnInit(): void {
    this._iconLibrary.addIcons(faFileDownload);

    const waitForUser = setInterval(() => {
      const user = this._userService.currentUser();
      if (user) {
        clearInterval(waitForUser);
        this._loadDirectionsButton();
      }
    }, 100);

    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this._router.navigateByUrl("console/national-project/national-projects"),
    });
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this._router.navigateByUrl("console/national-project/national-projects"),
    });
  }

  private _loadDirectionsButton(): void {
    const user = this._userService.currentUser();
    if (!user?.entityId) return;

    const typedUser = user as ExtendedUserModelType;
    if (
      (typedUser.entityId === this.constants.CONSTANT_PMO_ID || typedUser.minister || typedUser.directorGeneral) &&
      typedUser.directionFeatureEnabled
    ) {
      this._floatActions([
        {
          key: "direction",
          data: {
            itemId: Number(this.id()),
            itemTypeId: this.constants.CONSTANT_NATIONAL_PROJECTS,
          },
        },
      ]);
    }
  }
}
