import {
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  input,
  OnInit,
  Output,
  signal,
  viewChild,
} from "@angular/core";
import { FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, filter, forkJoin, ignoreElements, map, of, switchMap } from "rxjs";
import { tap } from "rxjs/operators";

import { AdaaBooleanType } from "../../../../../../adaa-types";
import { AdaaHelper, useNewDS } from "../../../../../core/utils";
import {
  FormCheckboxComponent,
  FormDropdownComponent,
  FormDropdownMultiComponent,
  FormDropdownTreeComponent,
  FormInputComponent,
  FormInputDateComponent,
  FormTwoWaySelectComponent,
} from "../../../../../shared/components";
import { Constants } from "../../../../../shared/constants/constants";
import { AdaaBoolean, Language, PageMode } from "../../../../../shared/constants/enums";
import { FormControlDisabledDirective } from "../../../../../shared/directives";
import {
  AffectedEntityKpi,
  AuxGovDirection,
  CommonKpiModelType,
  EntityModelType,
  GovDirection,
  type InitiativeModelType,
  InternationalOrganizationType,
  KpiTypeConstantType,
  NtkpiModelType,
  ObjectiveModelType,
  ObjectStatus,
  OrgUnit,
  OtherEntity,
  ParameterCatalog,
  PropTypeModelType,
  ServiceModel,
  SubGovDirection,
  TpMilestone,
  TpProjectDetails,
  UserModelType,
  ValueText,
} from "../../../../../shared/models";
import {
  DataTableDataService,
  EntitiesApiService,
  GovDirectionApiService,
  KpisApiService,
  KpisService,
  LanguageService,
  OrgUnitApiService,
  OtherEntitiesApiService,
  PillarsApiService,
  PropertiesService,
  SubGovDirectionApiService,
  SystemLayoutService,
  UsersApiService,
  UserService,
} from "../../../../../shared/services";
import { ContributingEntitiesDataType, formByKpiType, FormGroupTab } from "../../utils";
import {
  ContributingEntitiesDropdownFieldComponent,
  GovDirectionsFieldComponent,
  InfoTabService,
  InitiativesFieldComponent,
  MainOutcomeTypeFieldComponent,
  ObjectivesFieldComponent,
  ProjectMilestoneFieldComponent,
  ServicesFieldComponent,
  ServicesFieldOnChangeType,
  SponsorEntityFieldComponent,
  TpSelectionFieldComponent,
  TransformationalTargetFieldComponent,
} from "./lib";

export type NewInternationalOrgType = {
  createdBy: number;
  nameAE: string;
  nameEN: string;
  organizationId: number;
  status: number;
  updatedBy: number;
};

@Component({
  selector: "adaa-information-tab",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormDropdownComponent,
    FormInputComponent,
    FormInputDateComponent,
    FormDropdownTreeComponent,
    TranslateModule,
    FormControlDisabledDirective,
    FormCheckboxComponent,
    FormTwoWaySelectComponent,
    ObjectivesFieldComponent,
    ServicesFieldComponent,
    ProjectMilestoneFieldComponent,
    InitiativesFieldComponent,
    ContributingEntitiesDropdownFieldComponent,
    SponsorEntityFieldComponent,
    GovDirectionsFieldComponent,
    TpSelectionFieldComponent,
    MainOutcomeTypeFieldComponent,
    FormDropdownMultiComponent,
    TransformationalTargetFieldComponent,
  ],
  providers: [InfoTabService],
  styleUrl: "../styles.scss",
  templateUrl: "./information-tab.component.html",
})
export class InformationTabComponent implements OnInit {
  @Input({ alias: "validations" }) public set _validations(catalog: ParameterCatalog[]) {
    this.validations.set(catalog ?? []);
  }

  readonly languageService = inject(LanguageService);
  readonly kpisService = inject(KpisService);
  readonly infoTabService = inject(InfoTabService);
  private readonly _userService = inject(UserService);
  private readonly _propertiesService = inject(PropertiesService);
  private readonly _systemLayoutService = inject(SystemLayoutService);
  private readonly _orgUnitApiService = inject(OrgUnitApiService);
  private readonly _usersApiService = inject(UsersApiService);
  private readonly _kpisApiService = inject(KpisApiService);
  private readonly _toastrService = inject(ToastrService);
  private readonly _pillarsApiService = inject(PillarsApiService);
  private readonly _entitiesApiService = inject(EntitiesApiService);
  private readonly _govDirectionApiService = inject(GovDirectionApiService);
  private readonly _subGovDirectionApiService = inject(SubGovDirectionApiService);
  private readonly _dataTableDataService = inject(DataTableDataService);
  private readonly _otherEntitiesApiService = inject(OtherEntitiesApiService);

  kpi = input<Record<string, unknown>>();
  stagedKpi = input<Record<string, unknown>>();
  pageMode = input.required<PageMode>();
  kpiType = input.required<number>();

  nationalTargets = signal<NtkpiModelType[]>([]);
  auxGovDirections = signal<AuxGovDirection[]>([]);
  subGovDirections = signal<SubGovDirection[]>([]);
  govDirections = signal<GovDirection[]>([]);
  entities = signal<EntityModelType[]>([]);
  validations = signal<ParameterCatalog[]>([]);
  objectives = signal<ObjectiveModelType[]>([]);
  legacyKpis = signal<CommonKpiModelType[]>([]);
  owners = signal<UserModelType[]>([]);
  orgUnits = signal<OrgUnit[]>([]);
  props = signal<PropTypeModelType[]>([]);
  pillarOptions = signal<ValueText[]>([]);
  enablerEntityOptions = signal<ValueText[]>([]);
  affectedEntitiesOptions = signal<ValueText[]>([]);
  pmoOrgUnitsOptions = signal<ValueText[]>([]);
  pmoOwnersOptions = signal<ValueText[]>([]);
  subServices = signal<ServiceModel[]>([]);
  dateRange = signal<{ max: NgbDateStruct | null; min: NgbDateStruct | null }>({ max: null, min: null });
  showVeryImportantReason = signal<boolean>(false);
  otherEntities = signal<OtherEntity[]>([]);
  isTransformationalTargetSelected = signal<boolean>(false);

  disableTpSelectionField = computed(() => {
    const id = this.stagedKpi()?.id;
    const nationalProjectId = this.stagedKpi()?.nationalProjectId;
    return AdaaHelper.isDefined(nationalProjectId) && AdaaHelper.isDefined(id);
  });
  govDirectionLabel = computed(() => {
    if (this.isDTKPI()) return "dtkpi.nationalStrategy";
    if (this.isNTKPI()) return "directions.national_vision";
    return "directions.government_direction";
  });
  subGDirectionLabel = computed(() => {
    if (this.isDTKPI()) return "dtkpi.enablerObjective";
    if (this.isNTKPI()) return "directions.main_pillar";
    return "directions.sub_government";
  });
  isPmoUser = computed(() => this._userService.currentUser()?.entityId === Constants.CONSTANT_PMO_ID);
  classificationOptions = computed(() => {
    const list = (this.props() ?? []).filter(({ propType }) => propType === Constants.CONSTANT_CLASSIFICATION_TYPE);
    return AdaaHelper.setDropdownArray(list, "id", AdaaHelper.getFieldLanguage("name"));
  });
  perspectiveOptions = computed(() => {
    const list = (this.props() ?? []).filter(({ propType }) => propType === Constants.CONSTANT_PERSPECTIVE_TYPE);
    return AdaaHelper.setDropdownArray(list, "id", AdaaHelper.getFieldLanguage("name"));
  });
  monthsOptions = computed(() => {
    const list = (this.props() ?? []).filter(({ propType }) => propType === Constants.CONSTANT_MONTHS);
    return AdaaHelper.setDropdownArray(list, "id", AdaaHelper.getFieldLanguage("name"));
  });
  targetTypeOptions = computed(() => {
    const list = (this.props() ?? []).filter(({ propType }) => propType === Constants.CONSTANT_KPI_TARGET_TYPE);
    return AdaaHelper.setDropdownArray(list, "id", AdaaHelper.getFieldLanguage("name"));
  });
  internationalOrganizations = computed(() => {
    const list = (this.props() ?? []).filter(
      ({ propType }) => propType === Constants.CONSTANT_DIMENSIONS_VALUETYPE_NAME_LIST
    );

    return AdaaHelper.setDropdownArray(list, "id", AdaaHelper.getFieldLanguage("name"));
  });
  publishedStateOptions = computed(() => {
    const list = (this.props() ?? []).filter(({ propType }) => propType === Constants.CONSTANT_PUBLISHED_STATE);
    const ids = Array.from(new Set(list.map(({ id }) => id)));
    const items: PropTypeModelType[] = [];

    for (const id of ids) {
      const item = list.find((item) => item.id === id);
      if (item) items.push(item);
    }

    return AdaaHelper.setDropdownArray(items, "id", AdaaHelper.getFieldLanguage("name"));
  });
  targetCategoryOptions = computed(() => {
    const list = (this.props() ?? []).filter(({ propType }) => propType === Constants.CONSTANT_TARGET_CATEGORY);
    return AdaaHelper.setDropdownArray(list, "id", AdaaHelper.getFieldLanguage("name"));
  });
  ownersOptions = computed(() =>
    AdaaHelper.setDropdownArray(this.owners() ?? [], "id", AdaaHelper.getFieldLanguage("name"))
  );
  mainOutcomeList = computed(() =>
    (this.props() ?? []).filter(({ propType }) => propType === Constants.CONSTANT_MAIN_OUTCOME_TYPE_PROP)
  );
  legacyKpisOptions = computed(() => {
    const options: ValueText[] = [];
    const list = this.legacyKpis() ?? [];

    for (const item of list) {
      const refCode = item.refCode;
      const cycle = this._systemLayoutService.cycles().find(({ id }) => id === item.planId);
      const text = `
          ${AdaaHelper.getItemValueByToken(item, "name")}
          [${refCode} - ${AdaaHelper.getItemValueByToken(cycle, "name")}]
        `.trim();

      options.push({
        value: item.id,
        text,
      });
    }

    return options;
  });
  isSKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.SKPI);
  isOPM = computed(() => this.kpiType() === Constants.KPI_TYPE.OPM);
  isMOKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.MOKPI);
  isEKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.EKPI);
  isMasterEKPI = computed(
    () =>
      (this.pageMode() === PageMode.edit &&
        this.kpiType() === Constants.KPI_TYPE.EKPI &&
        !AdaaHelper.isDefined(this.stagedKpi()?.targetsInputEntityId)) ||
      (this.pageMode() === PageMode.create && this.kpiType() === Constants.KPI_TYPE.EKPI)
  );
  isMTKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.MTKPI);
  isNTKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.NTKPI);
  isNKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.NKPI);
  isDTKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.DTKPI);
  isUAE = computed(() => this.kpiType() === Constants.KPI_TYPE.UAE);
  isSRVKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.SRVKPI);
  isDerivedSKPI = computed(() => {
    if (!this.isSKPI()) return false;
    const kpi = this.stagedKpi() as unknown as { linkedKpiId: number };
    if (!kpi) return false;
    return AdaaHelper.isDefined(kpi.linkedKpiId);
  });
  checkEkpiReadOnlyField = computed(() => {
    if (!this.isEKPI()) return false;
    if (this.pageMode() === PageMode.create) return false;
    const kpi = this.stagedKpi() as unknown as { targetsInputEntityId: number };
    if (kpi && AdaaHelper.isDefined(kpi.targetsInputEntityId)) return true;
    return !AdaaHelper.isPMOEntity();
  });
  isFieldRequiredForNTKPIandDTKPI = computed(() => {
    if (this.isDTKPI() || this.isNTKPI()) return true;
    return !AdaaHelper.isPMOEntity();
  });
  checkNTkpiReadOnlyField = computed(() => {
    if (!this.isNTKPI()) return false;
    return !AdaaHelper.isPMOEntity();
  });
  checkDTkpiReadOnlyField = computed(() => {
    if (!this.isDTKPI()) return false;
    return !AdaaHelper.isPMOEntity();
  });
  disableProjectMilestoneField = computed(() => {
    if (this.pageMode() === PageMode.edit) {
      return this.kpi()?.status !== ObjectStatus.DRAFT && AdaaHelper.isDefined(this.stagedKpi()?.projectMilestoneId);
    }
    return false;
  });
  disableSponsorEntity = computed(() => {
    if (!this.isDTKPI() && !this.isNTKPI()) return false;
    if (!AdaaHelper.isPMOEntity()) return true;

    const kpi = this.kpi();
    return kpi?.status === ObjectStatus.ACTIVE;
  });
  disableMainOutcomeType = computed(() => {
    if (this.pageMode() === PageMode.create) return false;
    if (this.pageMode() === PageMode.edit && this.kpi()?.status === ObjectStatus.DRAFT) return false;
    //Check if the main outcome type is the last item in the list
    return this.kpi()?.mainOutcomeType === this.mainOutcomeList().at(-1)?.id;
  });
  readonly orgUnitsOptions = computed(() => {
    return [
      { value: undefined, text: this.languageService.translate("common.form.label.none") },
      ...AdaaHelper.setDropdownArray(this.orgUnits() ?? [], "id", AdaaHelper.getFieldLanguage("name"), "parentOrgUnit"),
    ];
  });

  mainOutcomeTypeField = viewChild<MainOutcomeTypeFieldComponent>("mainOutcomeTypeField");

  readonly isPMOEntity = () => AdaaHelper.isPMOEntity();
  readonly isDefined = AdaaHelper.isDefined.bind(AdaaHelper);
  readonly isFieldRequired = AdaaHelper.isFieldRequired.bind(AdaaHelper);

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  model: FormGroup;

  @Output() onInfoTabFormValueChange = new EventEmitter();

  public get initiativeTranslation() {
    if (useNewDS()) {
      return "national_projects.projects";
    }
    return "breadcrumbs.initiatives";
  }

  public get isArabic() {
    return AdaaHelper.getCurrentLang() === Language.Arabic;
  }

  public ngOnInit() {
    this._prepareForm(this.validations());

    this._fetchProps();
    this._captureVeryImportantValueChanges();
    this._captureSponsorEntityChanges();
    this._captureModelValueChanges();
    this._captureOrgUnitChanges();
    this._captureTransformationalTarget();
    this._onStartDateChange();
    this._fetchEntities();
    this._queryOtherEntities();

    if (this.isOPM()) {
      this.infoTabService.fetchInitiatives();
    }

    if (this.isMTKPI()) {
      this.infoTabService.fetchProjectMilestiones();
    }

    if (this.isNTKPI() || this.isDTKPI()) {
      this._fetchGovDirections();
    }

    if (this.isMOKPI()) {
      const kpiId = this.stagedKpi()?.id as number;
      this.infoTabService.fetchTProjects(kpiId);
    }

    if (this.isEKPI()) this._initEkpi();
  }

  public appendOrgUnit($event: number) {
    this.model?.get("orgUnitId")?.setValue($event);
  }

  public handleMainOutcomeTypeChanges(isTransformationalTargetSelected: boolean) {
    this.isTransformationalTargetSelected.set(isTransformationalTargetSelected);
    const transformationalTargetOption = this.mainOutcomeTypeField()?.transformationalTargetOption();

    if (
      !this.isTransformationalTargetSelected() &&
      this.stagedKpi()?.mainOutcomeType !== transformationalTargetOption?.id
    ) {
      this._captureNonTTargetMainOutcome();
      return;
    }

    if (this.isTransformationalTargetSelected()) {
      this._captureTTargetAsMainOutcome();
    } else {
      this._captureNonTTargetMainOutcome();
    }
  }

  public handleTpSelectionFieldChanges(data: number, list: TpProjectDetails[] = []) {
    const tp = list.find(({ id }) => data === id);

    if (this.model.controls["nationalProjectId"]?.dirty) {
      this._updateDatesOnParentObjectChanges({
        startDate: tp?.startDate as number,
        endDate: tp?.endDate as number,
      });
    }

    if (tp) {
      this._fetchOrgUnits(tp.entityId);
      this._captureTTargetOnTpChange(tp);
    }
  }

  public handleGovDirectionChanges(govDirectionId: number) {
    if (this.stagedKpi()?.directionId !== govDirectionId) {
      this.model.controls["subDirectionId"]?.setValue(null);
    }
    if (!AdaaHelper.isDefinedAndNotEmpty(govDirectionId)) return;

    if (govDirectionId === Constants.CONSTANT_SDG_GOV_DIR_ID) {
      const sponsorEntityId = this.stagedKpi()?.sponsorEntityId ?? AdaaHelper.entity?.id;
      this._fetchNationalTargets(sponsorEntityId as number);
    }
    this._fetchSubGovDirections(govDirectionId);
  }

  public handleSubGovDirectionChanges(subGovDirectionId: number) {
    if (this.stagedKpi()?.subDirectionId !== subGovDirectionId) {
      this.model.controls["auxiliaryDirectionId"]?.setValue(null);
    }
    if (!AdaaHelper.isDefinedAndNotEmpty(subGovDirectionId)) return;

    const direction = this.subGovDirections().find((e) => e.id === subGovDirectionId);
    this.auxGovDirections.set(direction?.auxiliaryDirectionRequests ?? []);
  }

  public handleInitiativesChanges(value: number, list: InitiativeModelType[]) {
    const item = list.find(({ id }) => value === id);
    if (!item) return;

    if (this.model.controls["initiativeId"]?.dirty) {
      this._updateDatesOnParentObjectChanges({
        endDate: item.endDate,
        startDate: item.startDate,
      });

      this._updateOrgUnitOnParentObjectChanges(item.orgUnitId);
    }
  }

  public handleObjectiveChanges($e: { value: number; list: ObjectiveModelType[] }) {
    this.objectives.set($e.list ?? []);
    if (this.isSKPI()) {
      const item = ($e.list ?? []).find(({ id }) => $e.value === id);
      if (!item) return;

      if (this.model.controls["objectiveId"]?.dirty) {
        this._updateDatesOnParentObjectChanges({
          endDate: item.endDate,
          startDate: item.startDate,
        });
      }

      this._updateOrgUnitOnParentObjectChanges(item.orgUnitId);
    }
  }

  public handleTpMilestoneChanges($e: number, list: TpMilestone[]) {
    const item = list.find(({ id }) => $e === id);
    if (!item) return;
    this._fetchOrgUnits(item.entityId);

    if (this.model.controls["projectMilestoneId"]?.dirty) {
      this._updateDatesOnParentObjectChanges({
        endDate: item.endDate,
        startDate: item.startDate,
      });
    }
  }

  public handleServicesChanges($e: ServicesFieldOnChangeType) {
    switch ($e.field) {
      case "mainService": {
        this.subServices.set($e.subServices ?? []);
        const objectiveId = this.model?.controls["objectiveId"]?.value;
        if (!objectiveId) {
          const item = $e.services.find(({ id }) => id === $e.value);
          this.model?.controls["objectiveId"]?.setValue(item?.objectiveId);
        }

        break;
      }

      case "subService": {
        const item = $e.subServices.find(({ id }) => id === $e.value);
        if (!item) return;

        if (this.model.controls["serviceId"]?.dirty) {
          this._updateDatesOnParentObjectChanges({
            endDate: item.endDate,
            startDate: item.startDate,
          });
        }

        this._updateOrgUnitOnParentObjectChanges(item.orgUnitId);

        break;
      }
    }
  }

  public onLegacyKpiSearch(term: string | undefined) {
    const startDate = this.model.get("startDate")?.value;
    if (!startDate) return;
    if (typeof term === "string" && !term.length) term = undefined;
    this._fetchLegacyKpis(startDate, term);
  }

  public checkDates($event: number, type: "startDate" | "endDate") {
    if (this.pageMode() === PageMode.create) return;

    const kpi = this.stagedKpi() as { hasActuals: boolean; startDate: number };
    const startDate = this.model.get(type)?.value;

    if (startDate < $event && kpi?.hasActuals) {
      this.model.get(type)?.setValue(kpi.startDate);

      this._toastrService.warning(this.languageService.translate("notification.warning.date_change_check_actuals"));
    }

    // TODO: handle SKPI end date targets evaluation
  }

  public submitInternationalOrganizations() {
    const user = this._userService.currentUser();
    const items: NewInternationalOrgType[] = [];
    const data = (this.stagedKpi()?.internationalOrganizations ?? []) as number[];
    const savedList = (this.kpi()?.internationalOrganizations ?? []) as InternationalOrganizationType[];
    const list = (this.props() ?? [])
      .filter(({ propType }) => propType === Constants.CONSTANT_DIMENSIONS_VALUETYPE_NAME_LIST)
      .filter(({ id }) => data?.findIndex(($i: number) => $i === id) !== -1);

    for (const $i of list) {
      const found = savedList.find(({ organizationId }) => organizationId === $i.id);
      if (found) {
        items.push(found as unknown as NewInternationalOrgType);
        continue;
      }

      items.push({
        createdBy: user?.id as number,
        nameAE: $i.nameAE,
        nameEN: $i.nameEN,
        organizationId: $i.id,
        status: ObjectStatus.ACTIVE,
        updatedBy: user?.id as number,
      });
    }

    return items;
  }

  public submitContributingEntities() {
    const list = this.stagedKpi()?.contributingEntities as number[];
    const savedList = (this.kpi()?.contributingEntities ?? []) as ContributingEntitiesDataType[];
    const entities = this.entities().filter(({ id }) => list?.findIndex((entityId: number) => entityId === id) !== -1);
    const items: ContributingEntitiesDataType[] = [];

    for (const $i of entities) {
      const kpiId = this.pageMode() === PageMode.edit ? this.stagedKpi()?.id : undefined;
      const found = savedList.find(({ entityId }) => entityId === $i.id);

      if (found) {
        items.push(found);
        continue;
      }

      items.push({
        otherEntityId: null,
        entityId: $i.id,
        otherEntityAE: $i.nameAE,
        otherEntityEN: $i.nameEN,
        itemType: this.kpiType(),
        status: ObjectStatus.ACTIVE,
        itemId: kpiId as number | undefined,
      });
    }

    return items;
  }

  public submitOtherContributingEntities() {
    const list = this.stagedKpi()?.otherContributingEntities as number[];
    const savedList = (this.kpi()?.contributingEntities ?? []) as ContributingEntitiesDataType[];
    const entities = this.otherEntities().filter(
      ({ id }) => list?.findIndex((entityId: number) => entityId === id) !== -1
    );
    const items: ContributingEntitiesDataType[] = [];

    for (const $i of entities) {
      const kpiId = this.pageMode() === PageMode.edit ? this.stagedKpi()?.id : undefined;
      const found = savedList.find(({ otherEntityId }) => otherEntityId === $i.id);

      if (found) {
        items.push(found);
        continue;
      }

      items.push({
        entityId: null,
        otherEntityId: $i.id,
        otherEntityAE: $i.nameAE,
        otherEntityEN: $i.nameEN,
        itemType: this.kpiType(),
        status: ObjectStatus.ACTIVE,
        itemId: kpiId as number | undefined,
      });
    }

    return items;
  }

  private _prepareForm(validations: ParameterCatalog[]) {
    const kpiType = Object.entries(Constants.KPI_TYPE).find(([_, val]) => val === this.kpiType());
    if (!kpiType) {
      throw new Error(`Unknown KPI Type ${this.kpiType()}`);
    }
    const [type] = kpiType as [Lowercase<KpiTypeConstantType>, number];

    const forms = formByKpiType[type.toLowerCase()];
    if (!forms) {
      throw new Error(`Form type ${type} not supported!`);
    }

    this.model = (forms as FormGroupTab).infoTab(validations);

    if (this.isOPM() || this.isSKPI() || this.isMTKPI() || this.isMOKPI() || this.isSRVKPI()) {
      this._fetchOrgUnits(AdaaHelper.entity?.id);
    }

    if (this.pageMode() === PageMode.edit) {
      const kpi = this.stagedKpi();
      if (!kpi) return;

      const controlKeys = Object.keys(kpi ?? {});

      for (const k of controlKeys) {
        const control = this.model.get(k);
        if (!control) continue;
        if (!AdaaHelper.isDefined(kpi[k])) continue;

        if (k === "startDate" || k === "endDate") {
          this.model.get(k)?.setValue(AdaaHelper.getDubaiTime(kpi[k] as number));
        } else {
          this.model.get(k)?.setValue(kpi[k]);
        }
      }

      const orgUnitId = this.model.get("orgUnitId")?.getRawValue();
      if (orgUnitId) this._fetchOwners(orgUnitId).subscribe();

      const startDate = this.model.get("startDate")?.value;
      if (startDate) this._fetchLegacyKpis(startDate);

      if (this.isSRVKPI() && this.model.get("veryImportant")?.value === AdaaBoolean.Y) {
        this.showVeryImportantReason.set(true);
        this.model?.get("veryImportantReasonAE")?.setValidators(Validators.required);
        this.model?.get("veryImportantReasonAE")?.updateValueAndValidity();
      }

      if (this.isSRVKPI() && !this.isPmoUser()) {
        this.model?.get("classification")?.disable();
        this.model?.get("importedValues")?.disable();
      }

      if (this.isEKPI()) {
        this._fetchEnablerEntities(this.model.get("ekpiPillarId")?.value);
        this._fetchOrgUnits(AdaaHelper.isPMOEntity() ? this.model.get("enablerEntityId")?.value : AdaaHelper.entity.id);

        const affectedEntities = this.model.get("affectedEntities")?.value ?? [];
        this.model.get("auxAffectedEntities")?.setValue(affectedEntities);

        const ids = affectedEntities.map((e: { entityId: number }) => e.entityId);
        if (ids) this.model.get("affectedEntitiesIds")?.setValue(ids);

        const pmoOrgUnitId = this.model.get("pmoOrgUnitId")?.value;
        if (pmoOrgUnitId) this._fetchPmoOwners(pmoOrgUnitId).subscribe();

        if (this.isEKPI() && !this.isPMOEntity()) {
          this._disableMasterEkpiForm();
          //NOTE: If not master ekpi the affectedEntities not required
          if (!this.isMasterEKPI()) {
            this.model.get("affectedEntities")?.clearValidators();
            this.model.get("affectedEntities")?.updateValueAndValidity();
            this.model.get("affectedEntitiesIds")?.clearValidators();
            this.model.get("affectedEntitiesIds")?.updateValueAndValidity();
          }
        }
      }

      if (this.isDTKPI() || this.isNTKPI()) {
        this.model.get("sponsorEntityId")?.setValue(this.stagedKpi()?.sponsorEntityId);
        this.model.get("directionId")?.setValue(this.stagedKpi()?.govDirectionId);
        const entities = (this.stagedKpi()?.contributingEntities || []) as ContributingEntitiesDataType[];

        // contributing entities
        this.model
          .get("contributingEntities")
          ?.setValue(entities.filter((item) => AdaaHelper.isDefined(item.entityId)).map(({ entityId }) => entityId));

        // other contributing entities
        this.model
          .get("otherContributingEntities")
          ?.setValue(
            entities
              .filter((item) => AdaaHelper.isDefined(item.otherEntityId))
              .map(({ otherEntityId }) => otherEntityId)
          );
      }

      if (this.isMOKPI() && AdaaHelper.isDefined(this.stagedKpi()?.transformationalTarget)) {
        const item = this.stagedKpi()?.transformationalTarget as Record<string, unknown>;
        const data = this.stagedKpi()?.transformationalTarget as { id: number };
        this.model.get("transformationalTarget")?.setValue(data.id);
        this.isTransformationalTargetSelected.set(item?.status === ObjectStatus.ACTIVE);
      }
    }

    if (this.isNTKPI()) {
      const internationalOrganizations = (this.stagedKpi()?.internationalOrganizations ?? []) as {
        organizationId: number;
      }[];

      this.model
        .get("internationalOrganizations")
        ?.setValue(internationalOrganizations.map((item) => item.organizationId));

      this.model
        .get("directionId")
        ?.setValue(this.stagedKpi()?.directionId ?? Constants.CONSTANT_WE_THE_UAE_2031_SUB_GOV_ID);
    }
  }

  private _onStartDateChange() {
    this.model
      ?.get("startDate")
      ?.valueChanges.pipe(
        this.#untilDestroy(),
        distinctUntilChanged(),
        filter((value) => AdaaHelper.isDefined(value))
      )
      .subscribe({
        next: (startDate) => this._fetchLegacyKpis(startDate),
      });
  }

  private _fetchProps() {
    this._propertiesService
      .getPropByIdList([
        Constants.CONSTANT_CLASSIFICATION_TYPE,
        Constants.CONSTANT_PERSPECTIVE_TYPE,
        Constants.CONSTANT_PUBLISHED_STATE,
        Constants.CONSTANT_TARGET_CATEGORY,
        Constants.CONSTANT_MONTHS,
        Constants.CONSTANT_DIMENSIONS_VALUETYPE_NAME_LIST,
        Constants.CONSTANT_KPI_TARGET_TYPE,
        Constants.CONSTANT_PUBLISHED_STATE,
        Constants.CONSTANT_MAIN_OUTCOME_TYPE_PROP,
      ])
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe((data) => this.props.set(data));
  }

  private _fetchSubGovDirections(govDirId: number) {
    this._subGovDirectionApiService
      .getAllSubsbyGovDirection(govDirId)
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => {
          this.subGovDirections.set(data ?? []);

          const id = this.model.controls["subDirectionId"]?.value;
          if (this.pageMode() === PageMode.edit && AdaaHelper.isDefined(id)) {
            this.handleSubGovDirectionChanges(id);
          }
        },
      });
  }

  private _fetchNationalTargets(entityId: number) {
    const itemTypeId = Constants.KPI_TYPE.NTKPI;
    const paramString = `itemType=${itemTypeId}&entityId=${entityId}`;

    this._dataTableDataService
      .getAllV2(undefined, undefined, "item", paramString, true, {
        searchFields: {
          status: {
            order: undefined,
            value: undefined,
            lov: [ObjectStatus.ACTIVE],
          },
        },
      })
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => {
          this.nationalTargets.set((data ?? []) as NtkpiModelType[]);
        },
      });
  }

  private _fetchGovDirections() {
    this._govDirectionApiService
      .getAllByVision(this.isNTKPI() || this.isNKPI() ? AdaaBoolean.Y : AdaaBoolean.N)
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => {
          this.govDirections.set(data?.filter((e) => e.status === Constants.OBJECT_STATUS.ACTIVE) ?? []);
        },
      });
  }

  private _fetchEntities() {
    this._entitiesApiService
      .getAllV2()
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => {
          this.entities.set(data ?? []);
        },
      });
  }

  private _queryOtherEntities() {
    this._otherEntitiesApiService
      .getAll()
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => {
          this.otherEntities.set(data ?? []);
        },
      });
  }

  private _fetchOrgUnits(entityId: number | undefined) {
    if (!entityId) {
      this.model.get("orgUnitId")?.setValue(null);
      this.orgUnits.set([]);
      return;
    }

    this._orgUnitApiService
      .getbyEntityidAndObjectype(entityId, this.kpisService.getKpiPermission(this.kpiType()))
      .subscribe({
        next: (response) => {
          if (response.inError) {
            this.orgUnits.set([]);
            return;
          }

          this.orgUnits.set(response.responseData ?? []);
          const isAvailable = this.orgUnits()?.find(({ id }) => id === this.model?.get("orgUnitId")?.getRawValue());
          if (!isAvailable) this.model?.get("orgUnitId")?.setValue(null);
        },
      });
  }

  private _captureTransformationalTarget() {
    this.model
      ?.get("transformationalTarget")
      ?.valueChanges.pipe(
        this.#untilDestroy(),
        filter((value) => AdaaHelper.isDefined(value))
      )
      .subscribe({
        next: (value: number) => {
          const nationalProjectId = this.stagedKpi()?.nationalProjectId as number | null;
          if (!AdaaHelper.isDefined(nationalProjectId)) return;
          const data = this.infoTabService.transformationProjects()?.find(({ id }) => id === nationalProjectId);
          this._processTTargetSelection(value, data?.refCode);
        },
      });
  }

  private _captureNonTTargetMainOutcome() {
    if (this.isTransformationalTargetSelected()) return;
    this.model.get("transformationalTarget")?.setValue(null);

    // Enable fields.
    this.model.get("nameAE")?.enable();
    this.model.get("nameEN")?.enable();
    this.model.get("dscAE")?.enable();
    this.model.get("dscEN")?.enable();
  }

  private _captureTTargetOnTpChange({ refCode, id }: TpProjectDetails) {
    if (!this.isTransformationalTargetSelected()) return;
    if (id !== this.stagedKpi()?.nationalProjectId) {
      this.model.controls["transformationalTarget"]?.setValue(null);
    }

    const kpiId = this.stagedKpi()?.id as number | undefined;

    this.infoTabService.fetchTransformationalTargets(id, kpiId).subscribe({
      next: () => {
        const value = this.stagedKpi()?.transformationalTarget as number;
        this._processTTargetSelection(value, refCode);
      },
    });
  }

  private _captureTTargetAsMainOutcome() {
    if (!this.isTransformationalTargetSelected()) return;
    const nationalProjectId = this.stagedKpi()?.nationalProjectId as number | null;
    const kpiId = this.stagedKpi()?.id as number | undefined;

    if (!AdaaHelper.isDefined(nationalProjectId)) return;

    const data = this.infoTabService.transformationProjects()?.find(({ id }) => id === nationalProjectId);

    // Remove name & dsc.
    this.model.get("nameAE")?.setValue(null);
    this.model.get("nameEN")?.setValue(null);
    this.model.get("dscAE")?.setValue(null);
    this.model.get("dscEN")?.setValue(null);

    this.infoTabService.fetchTransformationalTargets(nationalProjectId, kpiId).subscribe({
      next: () => {
        const value = this.stagedKpi()?.transformationalTarget as number;
        this._processTTargetSelection(value, data?.refCode);
      },
    });
  }

  private _processTTargetSelection(value?: number, refCode?: string) {
    if (!value) return;
    const list = this.infoTabService.transformationalTargets() ?? [];
    const found = list.find(({ id }) => id === value);
    if (found) {
      this.model.get("nameAE")?.setValue(`${found.nameAE} (${refCode})`);
      this.model.get("nameEN")?.setValue(`${found.nameEN} (${refCode})`);
      this.model.get("dscAE")?.setValue(found.dscAE);
      this.model.get("dscEN")?.setValue(found.dscEN);
      this.model.get("nameAE")?.disable();
      this.model.get("nameEN")?.disable();
      this.model.get("dscAE")?.disable();
      this.model.get("dscEN")?.disable();
    }
  }

  private _captureOrgUnitChanges() {
    this.model
      ?.get("orgUnitId")
      ?.valueChanges.pipe(
        this.#untilDestroy(),
        distinctUntilChanged(),
        switchMap((value) => this._fetchOwners(value))
      )
      .subscribe();
  }

  private _capturePmoOrgUnitChanges() {
    this.model
      ?.get("pmoOrgUnitId")
      ?.valueChanges.pipe(
        this.#untilDestroy(),
        distinctUntilChanged(),
        switchMap((value) => this._fetchPmoOwners(value))
      )
      .subscribe();
  }

  private _captureSponsorEntityChanges() {
    this.model
      .get("sponsorEntityId")
      ?.valueChanges.pipe(this.#untilDestroy())
      .subscribe({
        next: (value: number | undefined) => this._fetchOrgUnits(value),
      });
  }

  private _fetchOwners(id: number | undefined) {
    if (!id) {
      this.model?.get("owner")?.setValue(null);
      return of(null);
    }

    return this._usersApiService.getOwnersPerOrgUnit(id).pipe(
      filter((res) => !res.inError),
      map((res) => res.responseData),
      tap({
        next: (data) => {
          this.owners.set(data ?? []);
          this._updateOwnerOnParentObjectChanges();

          const isOwnerAvailable = this.owners()?.find(({ id }) => id === this.model?.get("owner")?.getRawValue());
          if (!isOwnerAvailable) this.model?.get("owner")?.setValue(null);
        },
      }),
      ignoreElements()
    );
  }

  private _fetchLegacyKpis(startDate: number, searchTerm?: string) {
    const LIMIT = 30;
    const legacyKpiId = this.model.get("legacyKpi")?.value;
    let kpiId!: number;
    if (legacyKpiId) kpiId = legacyKpiId;
    else if (this.kpi()) kpiId = this.kpi()?.id as number;

    this._kpisApiService
      .getLegacyKpis({
        kpiType: this.kpiType(),
        startDate,
        limit: LIMIT,
        kpiId,
        searchTerm,
      })
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => {
          this.legacyKpis.set(data ?? []);
        },
      });
  }

  private _captureModelValueChanges() {
    this.model?.valueChanges.pipe(this.#untilDestroy()).subscribe({
      next: () => this.onInfoTabFormValueChange.emit(this.model.getRawValue()),
    });
  }

  private _updateOrgUnitOnParentObjectChanges(orgUnitId?: number) {
    if (!AdaaHelper.isDefined(orgUnitId)) return;
    const list = this.orgUnits() ?? [];
    const item = list.find(({ id }) => id === orgUnitId);
    if (!item) return;

    this.model.controls["orgUnitId"]?.setValue(item.id);
  }

  private _updateOwnerOnParentObjectChanges() {
    let owner: number | undefined;
    let list: { id: number; owner: number }[] = [];
    let key!: string;

    if (this.isSRVKPI()) {
      key = "serviceId";
      list = this.subServices() as { id: number; owner: number }[];
    } else if (this.isOPM()) {
      key = "initiativeId";
      list = this.infoTabService.initiatives() as { id: number; owner: number }[];
    } else if (this.isMTKPI()) {
      key = "projectMilestoneId";
      list = this.infoTabService.projectMilestones() as { id: number; owner: number }[];
    } else if (this.isSKPI()) {
      key = "objectiveId";
      list = this.objectives() as { id: number; owner: number }[];
    }

    if (!this.model.contains(key)) return;
    if (!Array.isArray(list)) return;

    // NOTE: get owner from parent object
    let value = this.model?.controls[key]?.value;
    const item = list.find(({ id }) => id === value);
    if (item) owner = item?.owner;

    // NOTE: check if owner is already set
    if (!AdaaHelper.isDefined(owner)) return;
    value = this.model.controls["owner"]?.value;
    if (AdaaHelper.isDefined(value)) return;

    const itemExists = (this.owners() ?? []).find(({ id }) => id === owner);
    if (!itemExists) return;

    // NOTE: append the parent object owner as kpi owner
    const { id } = itemExists;
    this.model.controls["owner"]?.setValue(id);
  }

  private _updateDatesOnParentObjectChanges(value: { startDate: number; endDate: number }) {
    const startDate = AdaaHelper.getDubaiTime(value.startDate);
    const endDate = AdaaHelper.getDubaiTime(value.endDate);

    this.model.get("startDate")?.setValue(startDate);
    this.model.get("endDate")?.setValue(endDate);

    this.dateRange.set({
      max: {
        year: new Date(endDate).getFullYear(),
        month: new Date(endDate).getMonth() + 1,
        day: new Date(endDate).getDate(),
      },
      min: {
        year: new Date(startDate).getFullYear(),
        month: new Date(startDate).getMonth() + 1,
        day: new Date(startDate).getDate(),
      },
    });
  }

  private _captureVeryImportantValueChanges() {
    this.model
      .get("veryImportant")
      ?.valueChanges.pipe(this.#untilDestroy())
      .subscribe({
        next: (value: AdaaBooleanType) => {
          this.showVeryImportantReason.set(value === AdaaBoolean.Y);

          if (!this.showVeryImportantReason()) {
            this.model?.get("veryImportantReasonAE")?.removeValidators(Validators.required);
            this.model?.get("veryImportantReasonAE")?.setValue(null);
          } else {
            this.model?.get("veryImportantReasonAE")?.setValidators(Validators.required);
          }
          this.model?.get("veryImportantReasonAE")?.updateValueAndValidity();
        },
      });
  }

  private _initEkpi() {
    this._captureEkpiPillarIdChanges();
    this._captureEnablerEntityIdChanges();
    this._capturePmoOrgUnitChanges();
    this._captureAffectedEntitiesIdsChanged();

    this._fetchEkpiDdlsData();

    if (this.isPMOEntity()) this._fetchPmoOrgUnits();
  }

  private _fetchEkpiDdlsData() {
    forkJoin({
      pillars: this._pillarsApiService.getPillars(true),
      entities: this._entitiesApiService.getAll(AdaaBoolean.N),
    }).subscribe({
      next: (results) => {
        if (!results.pillars.inError)
          this.pillarOptions.set(
            AdaaHelper.setDropdownArray(results.pillars.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.entities.inError)
          this.affectedEntitiesOptions.set(
            AdaaHelper.setDropdownArray(
              results.entities.responseData.filter((e) => e.id !== Constants.CONSTANT_PMO_ID),
              "id",
              AdaaHelper.getFieldLanguage("name")
            )
          );
      },
    });
  }

  private _captureEkpiPillarIdChanges() {
    this.model
      .get("ekpiPillarId")
      ?.valueChanges.pipe(this.#untilDestroy())
      .subscribe({
        next: (value: number | undefined) => this._fetchEnablerEntities(value),
      });
  }

  private _captureEnablerEntityIdChanges() {
    this.model
      .get("enablerEntityId")
      ?.valueChanges.pipe(this.#untilDestroy())
      .subscribe({
        next: (value: number | undefined) => {
          if (!value || !AdaaHelper.isPMOEntity(value)) this.model.get("otherEntityAE")?.setValue(null);

          this._fetchOrgUnits(value);
        },
      });
  }

  //This will add/remove the entities to affectedEntities
  private _captureAffectedEntitiesIdsChanged() {
    this.model
      .get("affectedEntitiesIds")
      ?.valueChanges.pipe(this.#untilDestroy())
      .subscribe({
        next: (value: number[] | undefined) => {
          value = value ?? [];
          const affectedEntities: AffectedEntityKpi[] = [];
          const auxAffectedEntities = (this.model.get("auxAffectedEntities")?.value as AffectedEntityKpi[]) ?? [];

          value.forEach((id) => {
            const entity = auxAffectedEntities.find((e) => e.entityId === id);

            if (!entity) {
              const option = this.affectedEntitiesOptions().find((e) => e.value === id);
              affectedEntities.push({
                entityId: id,
                entityNameEN: option?.text,
                entityNameAE: option?.text,
                dataInputEntity: undefined,
                approvalActuals: Constants.CONSTANT_DATA_INPUT.ENABLER,
                approvalTargets: Constants.CONSTANT_DATA_INPUT.PMO,
                inputActuals: Constants.CONSTANT_DATA_INPUT.ENABLER,
                inputTargets: Constants.CONSTANT_DATA_INPUT.ENABLER,
                status: Constants.OBJECT_STATUS.DRAFT,
              });
            } else affectedEntities.push(entity);
          });

          this.model.get("affectedEntities")?.setValue(affectedEntities);
        },
      });
  }

  private _fetchEnablerEntities(pillarId: number | undefined) {
    if (!pillarId) {
      this.model.get("enablerEntityId")?.setValue(null);
      this.enablerEntityOptions.set([]);
      return;
    }

    this._entitiesApiService.getEntitiesByPillarId(pillarId).subscribe({
      next: (response) => {
        if (response.inError) {
          this.model.get("enablerEntityId")?.setValue(null);
          this.enablerEntityOptions.set([]);
        }

        this.enablerEntityOptions.set(
          AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"))
        );

        const isEntityAvailable = this.enablerEntityOptions()?.find(
          (e) => e.value === this.model.get("enablerEntityId")?.value
        );
        if (!isEntityAvailable) this.model.get("enablerEntityId")?.setValue(null);
      },
    });
  }

  private _fetchPmoOrgUnits() {
    this._orgUnitApiService
      .getbyEntityidAndObjectype(Constants.CONSTANT_PMO_ID, this.kpisService.getKpiPermission(this.kpiType()))
      .subscribe({
        next: (response) => {
          if (response.inError) return;

          this.pmoOrgUnitsOptions.set([
            { value: undefined, text: this.languageService.translate("common.form.label.none") },
            ...AdaaHelper.setDropdownArray(
              response.responseData ?? [],
              "id",
              AdaaHelper.getFieldLanguage("name"),
              "parentOrgUnit"
            ),
          ]);
        },
      });
  }

  private _fetchPmoOwners(id: number | undefined) {
    if (!id) {
      this.model?.get("owner")?.setValue(null);
      return of(null);
    }

    return this._usersApiService.getOwnersPerOrgUnit(id).pipe(
      filter((res) => !res.inError),
      map((res) => res.responseData),
      tap({
        next: (data) => {
          const isOwnerAvailable = data?.find(({ id }) => id === this.model?.get("pmoOwner")?.value);
          if (!isOwnerAvailable) this.model?.get("pmoOwner")?.setValue(null);

          this.pmoOwnersOptions.set(AdaaHelper.setDropdownArray(data, "id", AdaaHelper.getFieldLanguage("name")));
        },
      }),
      ignoreElements()
    );
  }

  private _disableMasterEkpiForm() {
    const disabledFields = [
      "nameEN",
      "nameAE",
      "dscEN",
      "dscAE",
      "ekpiPillarId",
      "enablerEntityId",
      "scopeMeasureAE",
      "legacyKpi",
    ];

    disabledFields.forEach((e) => this.model.get(e)?.disable());
  }
}
